<template lang="pug">
v-dialog(
  v-model="openDialog"
  eager
  width="800"
)
  v-card
    v-toolbar(
      color="green"
      flat
      dark
      dense
    )
      span Create
    v-container
      v-row
        v-col(cols="4")
          v-autocomplete(
            :items="stockList"
            :loading="!stockList.length"
            item-value="id"
            label="Stock"
            item-text="description"
            v-model="stockId"
            :filter="filterStockAutocomplete"
            :error-messages="orderDetailStorePostErrors.stock_id"
          )
            template(v-slot:selection="{ item }")
              span.primary--text.mr-2 {{ item.id }}
            template(v-slot:item="{ item }")
              span.primary--text.mr-2 {{ item.id }}
              small.blue-grey--text {{ item.description }}
        v-col(cols="4")
          v-autocomplete(
            :items="statuses"
            :loading="!statuses.length"
            item-value="id"
            item-text="name"
            label="Status"
            v-model="orderDetailStatusId"
            :error-messages="orderDetailStorePostErrors.order_detail_status_id"
          )
        v-col(cols="4")
          v-autocomplete(
            label="Unit"
            :items="units"
            v-model="unit"
            :error-messages="orderDetailStorePostErrors.unit"
          )
        v-col(cols="4")
          v-text-field(
            label="Quantity"
            type="number"
            v-model.number="quantity"
            :error-messages="orderDetailStorePostErrors.quantity"
          )
        v-col(cols="4")
          v-text-field(
            label="SRP"
            type="number"
            v-model.number="unitPrice"
            :error-messages="orderDetailStorePostErrors.unit_price"
          )
        v-col(cols="4")
          v-text-field(
            label="Discount"
            v-model="discount"
            :error-messages="orderDetailStorePostErrors.discount"
          )
        v-col(cols="4")
          v-text-field(
            label="Date Allocated"
            type="date"
            v-model="dateAllocated"
            :error-messages="orderDetailStorePostErrors.date_allocated"
          )
        v-col(cols="3")
          v-btn(
            color="green"
            dense
            @click="store"
            block
            :loading="orderDetailStorePosting"
            dark
          )
            span Save
        v-col(cols="3")
          v-btn(
            color="red"
            dense
            @click="openDialog = false"
            block
            dark
          )
            span cancel
</template>
<script>
import orderDetailRepository from '@/repositories/order-detail.repository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'

const [orderDetailStoreVars, orderDetailStoreVarNames] = requestVars({ identifier: 'order-detail-store', request: 'post', hasData: false })

const orderDetailStoreHandler = new VueRequestHandler(null, orderDetailStoreVarNames)

const inputVars = () => ({
  id: null,
  stockId: null,
  unit: null,
  quantity: null,
  unitPrice: null,
  discount: null,
  orderDetailStatusId: null,
  dateAllocated: Window.getCurrentDate(),
})

export default {
  name: 'CreateOrderDetail',
  props: ['value', 'orderData', 'excludedStocks', 'stocks', 'statuses'],
  data () {
    return {
      ...orderDetailStoreVars,
      ...inputVars(),
    }
  },
  computed: {
    orderId () {
      return this.$route.params.orderId
    },
    openDialog: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      },
    },
    units () {
      const stock = this.selectedStock || {}
      if (stock.stock_category_id === 'EG01') {
        return ['egg']
      }
      return [
        'kg',
        'bag',
        'lot',
      ]
    },
    stockData () {
      return this.stockList.find(item => item.id === this.stockId) || {}
    },
    stockList () {
      return this.stocks
        .filter(item => {
          if (this.excludedStocks.includes(item.id)) return false
          return this.orderData.revenueSourceId === item.revenue_source.id
        })
    },
  },
  watch: {
    unit () {
      this.fillInUnitPrice()
    },
    stockData () {
      this.fillInUnitPrice()
    },
  },
  methods: {
    filterStockAutocomplete (item, queryText) {
      const regExp = new RegExp(queryText, 'i')
      return regExp.test(item.id) || regExp.test(item.description)
    },
    fillInUnitPrice () {
      if (!this.stockId || !this.unit) return
      if (this.stockData.stock_category_id === 'EG01') {
        this.balutUnitPrice(this.stockData)
        return
      }
      this.defaultUnitPrice()
    },
    balutUnitPrice () {
      this.unitPrice = this.stockData.default_detail.price
    },
    defaultUnitPrice () {
      const { unit } = this
      if (unit === 'bag') {
        this.unitPrice = this.stockData.feed_detail.resell_per_bag || null
        return
      }
      this.unitPrice = this.stockData.feed_detail.resell_per_kilo || null
    },
    getInputData () {
      return Object.keys(inputVars())
        .concat(['orderId'])
        .reduce((result, key) => {
          result[key.camelToSnakeCase()] = this[key]
          return result
        }, {})
    },
    store () {
      const handler = orderDetailStoreHandler
      const repository = orderDetailRepository.store
      const { id, ...data } = this.getInputData()
      handler.setVue(this)
      handler.execute(repository, [data], this.reset)
    },
    reset () {
      const defaults = inputVars()
      this.openDialog = false
      Object.keys(defaults)
        .forEach(key => {
          this[key] = defaults[key]
        })
    },
  },
}
</script>